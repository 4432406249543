import { axios } from '@/utils/axios';

const RESOURCE_CITY  = '/city';
const RESOURCE_PRIVILEGE = '/privilege'
const RESOURCE_STREET  = '/street';
const RESOURCE_BUILDING  = '/building';
const RESOURCE_BANK = '/bank'
const RESOURCE_FLAT_INDICATOR = '/flat_indicator'
const RESOURCE_RESPONSE_PEOPLE = '/response-people'
const RESOURCE_TARIFF_GROUP_TYPE = '/tariff_group'
const FLAT_COUNTER_READING = '/flat-counter'
const CHART_OF_ACCOUNT = '/accounting/chart-of-account'
const RESOURCE_DOCUMENT_GROUP_TYPE = '/organization-documents/document-group-type'
const RESOURCE_DOCUMENT_TYPE = '/organization-documents/document-type'
const RESOURCE_UNIT_TYPE = '/flat_indicator/unit'
const RESOURCE_COUNTER_CONTRACTOR = '/counter-contractor'
const RESOURCE_COUNTER_INSTALLATION_PLACE = '/counter-installation-place'

const RESOURCE_ACTION = '/select'

export default {
    city_select(payload) {
        return axios.post(`${RESOURCE_CITY}${RESOURCE_ACTION}`, payload || {})
    },
    privilege_category_select(payload) {
        return axios.post(`${RESOURCE_PRIVILEGE}${RESOURCE_ACTION}-category`, payload || {})
    },
    street_select(payload) {
        return axios.post(`${RESOURCE_STREET}${RESOURCE_ACTION}`, payload || {})
    },
    building_select(payload) {
        return axios.post(`${RESOURCE_BUILDING}${RESOURCE_ACTION}`, payload || {})
    },
    bank_select(payload) {
        return axios.post(`${RESOURCE_BANK}${RESOURCE_ACTION}`, payload || {})
    },
    flat_indicator_select(payload) {
        return axios.post(`${RESOURCE_FLAT_INDICATOR}${RESOURCE_ACTION}`, payload || {})
    },
    position_select(payload) {
        return axios.post(`${RESOURCE_RESPONSE_PEOPLE}/position${RESOURCE_ACTION}`, payload || {})
    },
    tariff_group_type_select(payload) {
        return axios.get(`${RESOURCE_TARIFF_GROUP_TYPE}/type${RESOURCE_ACTION}`, payload || {})
    },
    natural_person_select(payload) {
        return axios.post(`${RESOURCE_RESPONSE_PEOPLE}/natural${RESOURCE_ACTION}`, payload || {})
    },
    flat_counter_select(payload) {
        return axios.post(`${FLAT_COUNTER_READING}${RESOURCE_ACTION}`, payload || {})
    },
    chart_of_account_select(payload) {
        return axios.post(`${CHART_OF_ACCOUNT}${RESOURCE_ACTION}`, payload || {})
    },
    document_group_type_select() {
        return axios.get(`${RESOURCE_DOCUMENT_GROUP_TYPE}${RESOURCE_ACTION}`)
    },
    document_type_select() {
        return axios.get(`${RESOURCE_DOCUMENT_TYPE}${RESOURCE_ACTION}`)
    },
    unit_type_select() {
        return axios.post(`${RESOURCE_UNIT_TYPE}${RESOURCE_ACTION}`)
    },
    counter_contractor_select() {
        return axios.post(`${RESOURCE_COUNTER_CONTRACTOR}${RESOURCE_ACTION}`)
    },
    counter_installation_place_select() {
        return axios.post(`${RESOURCE_COUNTER_INSTALLATION_PLACE}${RESOURCE_ACTION}`)
    }
}